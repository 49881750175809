import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BarcodeScannerService } from '@proman/services/barcode-scanner.service';
import { QrCodeUrlService } from '../../qr/services/qr-code-url.service';
import { CONFIG } from '@proman/config';
import { Router } from '@angular/router';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { RequestService } from '@proman/services/request.service';
import { ToastService } from "@proman/services/toast.service";
import {FilterService} from "@proman/services/filter.service";

@Component({
    selector: 'pm-url-read-dialog',
    template: `
        <pro-dialog-title title="go_to_address" [pulse]="true"></pro-dialog-title>
        <div mat-dialog-content>
            <pm-camera-qr-reader (onCodeRead)="onScan($event)"></pm-camera-qr-reader>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class UrlReadDialogComponent implements OnInit, OnDestroy {
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
    read: any = '';

    constructor(
        private Request: RequestService,
        private Router: Router,
        private QrCode: QrCodeUrlService,
        private BarcodeScanner: BarcodeScannerService,
        private dialogRef: MatLegacyDialogRef<UrlReadDialogComponent>,
        private Toast: ToastService,
        private Filter: FilterService,
    ) {

    }

    ngOnInit(): void {
        this.BarcodeScanner.listen(this.onScan);
    }

    onScan = (qrCode: string) => {
        if (qrCode.includes(CONFIG.root)) {
            let part = qrCode.substr(qrCode.indexOf(CONFIG.root) + CONFIG.root.length);
            this.Toast.pop('info', this.Filter.translate('navigating_to') + `: ${qrCode}`);
            this.handleUrl(part);

        } else {
          this.Toast.pop('error', this.Filter.translate('not_found') + `: ${qrCode}`);
        }

        this.dialogRef.close();

    };

    handleUrl(url: string) {

        if (url.startsWith('api')) {
            let data: any = {};
            const parts = url.split('?');
            const endpoint = parts[0];

            if (parts.length > 1) {
                for (let value of parts[1].split('&')) {
                    let items = value.split('=');
                    data[items[0]] = items[1];
                }
            }

            this.Request.post(`${CONFIG.root}${endpoint}`, data).then(() => {
                this.Toast.pop('success', 'success');
            }).catch((err) => {
                this.Toast.pop('error', err.error?.message);
            });

        } else {
            this.Router.navigate([url]);

        }

    }

    ngOnDestroy() {
        this.BarcodeScanner.destroy();
    }
}
