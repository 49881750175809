import { filter } from '@proman/rxjs-common';
import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ToolbarService } from '../services/toolbar.service';
import { TitleService } from '../services/title.service';
import { Location } from '@angular/common';
import { PromanStateService } from '../services/proman-state.service';
import { NavigationEnd, Router } from '@angular/router';
import { Entity } from '@proman/services/entity.service';
import { CursorLoadingService } from '@proman/services/cursor-loading.service';
import { Action } from '@proman/interfaces/object-interfaces';
import { ToolbarActionsDialogComponent } from './toolbar-actions-dialog.component';
import { Dialog } from '../services/dialog.service';
import { LocalStorageService } from '@proman/services/local-storage.service';
import { TagType } from '@proman/resources/tag';

const TOOLBAR_ACTIONS_STORAGE_KEY = 'toolbar_actions';

const toolbarStates: {
    [name: string]: {
        url: string;
        name: string;
    };
    } = {
    'order-types': {
        url: '/orders/types',
        name: 'types',
    },
    'order-subtypes': {
        url: '/orders/subtypes',
        name: 'subtypes',
    },
    'orders': {
        url: '/orders',
        name: 'orders',
    },
    'production_products': {
        url: '/production/products',
        name: 'products',
    },
    'production': {
        url: '/production',
        name: 'production',
    },
    'articles-sort': {
        url: '/articles/sort',
        name: 'ordering',
    },
    'article_test_types': {
        url: '/articles/article-test-types',
        name: 'article_test_types',
    },
    'articles-types': {
        url: '/articles/types',
        name: 'types',
    },
    'articles-test': {
        url: '/articles-tests',
        name: 'tests',
    },
    'articles-parameters': {
        url: '/articles/parameters',
        name: 'parameters',
    },
    'articles': {
        url: '/articles',
        name: 'articles',
    },
    'article-categories': {
        url: '/article-categories',
        name: 'article_categories',
    },
    'min-quantity': {
        url: '/materials/min-quantity',
        name: 'critical_quantities',
    },
    'materials-stock': {
        url: '/materials/stock',
        name: 'stock',
    },
    'materials': {
        url: '/materials',
        name: 'materials',
    },
    'material-types': {
        url: '/material-types',
        name: 'material_types',
    },
    'material-categories': {
        url: '/material-categories',
        name: 'material_categories',
    },
    'purchase-categories': {
        url: '/purchase/categories',
        name: 'categories',
    },
    'purchase-templates': {
        url: '/purchase/templates',
        name: 'templates',
    },
    'purchase': {
        url: '/purchase',
        name: 'purchase',
    },
    'expressions': {
        url: '/functions',
        name: 'expressions',
    },
    'agents_permissions': {
        url: '/agents/agents-permissions',
        name: 'agents_permissions',
    },
    'agents': {
        url: '/agents',
        name: 'agents',
    },
    'customers_users': {
        url: '/customers/employees-users',
        name: 'customers_users',
    },
    'customer_employees': {
        url: '/customers/customer-employees',
        name: 'employees',
    },
    'customers_permissions': {
        url: '/customers/customers-permissions',
        name: 'customers_permissions',
    },
    'departments': {
        url: '/customers/departments',
        name: 'departments',
    },
    'debts': {
        url: '/customers/debts',
        name: 'debts',
    },
    'customer_types': {
        url: '/customers/customer-types',
        name: 'customer_types',
    },
    'customers_cards': {
        url: '/customers/cards',
        name: 'cards',
    },
    'customers': {
        url: '/customers',
        name: 'customers',
    },
    'claim_types': {
        url: '/customers/claims/types',
        name: 'types',
    },
    'claims': {
        url: '/customers/claims',
        name: 'customer_claims',
    },
    'dev-projects': {
        url: '/dev-projects',
        name: 'development',
    },
    'templates': {
        url: '/system-settings/templates',
        name: 'templates',
    },
    'discounts': {
        url: '/system-settings/discounts',
        name: 'discounts',
    },
    'clients-portal': {
        url: '/system-settings/clients-portal',
        name: 'clients_portal',
    },
    'shop-options': {
        url: '/system-settings/shop-options',
        name: 'clients_portal',
    },
    'loose-relations': {
        url: '/system-settings/loose-relations',
        name: 'relation_types',
    },
    'employees-documents-defect-acts': {
        url: '/employees/documents/defect-acts',
        name: 'defect-acts',
    },
    'employees-documents': {
        url: '/employees/documents',
        name: 'employees_documents',
    },
    'employee-payments': {
        url: '/employee/payments',
        name: 'employee_payments',
    },
    'employees': {
        url: '/employees',
        name: 'employees',
    },
    'parameters': {
        url: '/parameters',
        name: 'parameters',
    },
    'jobs': {
        url: '/jobs',
        name: 'jobs',
    },
    'carriers': {
        url: '/carriers',
        name: 'carriers',
    },
    'orders-products': {
        url: '/orders-products',
        name: 'orders_products',
    },
    'balance': {
        url: '/accounting/balance',
        name: 'balance',
    },
    'account-invoices-products': {
        url: '/accounting/invoices/products',
        name: 'products',
    },
    'account-invoices': {
        url: '/accounting/invoices',
        name: 'invoices',
    },
    'accounting-reports': {
        url: '/accounting/accounting-reports',
        name: 'Accounting Reports',
    },
    'countries': {
        url: '/countries',
        name: 'countries',
    },
    'accounting-props': {
        url: '/accounting/props',
        name: 'accounting_props',
    },
    'accounting/payments/coupons': {
        url: '/accounting/payments/coupons',
        name: 'coupons',
    },
    'accounting/payments': {
        url: '/accounting/payments',
        name: 'payments',
    },
    'accounting/orders': {
        url: '/accounting/orders',
        name: 'orders',
    },
    'accounting/books': {
        url: '/accounting/books',
        name: 'accounting_books',
    },
    'cash-registers': {
        url: '/accounting/cash-registers',
        name: 'cash_registers',
    },
    'tags': {
        url: '/system-settings/tags',
        name: 'tags',
    },
    'time_tags': {
        url: '/system-settings/time-tags',
        name: 'time_restrictions',
    },
    'events-log': {
        url: '/system-settings/events-log',
        name: 'events_log',
    },
    'boards': {
        url: '/events/boards',
        name: 'boards',
    },
    'resource-bookings': {
        url: '/events/resource-bookings',
        name: 'operations',
    },
    'events-parameters': {
        url: '/events/parameters',
        name: 'operations-parameters',
    },
    'calendar_production': {
        url: '/events/calendar/production',
        name: 'production',
    },
    'events': {
        url: '/events',
        name: 'operations',
    },
    'calendar': {
        url: '/events/calendar',
        name: 'calendar',
    },
    'priority-levels': {
        url: '/system-settings/priorities',
        name: 'priority_levels',
    },
    'personal-dynamic-tables': {
        url: '/dynamic-tables/personal',
        name: 'personal_tables',
    },
    'dynamic-queries': {
        url: '/dynamic-tables/dynamic-queries',
        name: 'dynamic_queries',
    },
    'aggregated-tables': {
        url: '/dynamic-tables/aggregated-tables',
        name: 'scoreboards',
    },
    'charts': {
        url: '/dynamic-tables/charts',
        name: 'charts',
    },
    'dynamic-tables': {
        url: '/dynamic-tables',
        name: 'dynamic_tables',
    },
    'consumer-map': {
        url: '/consumers/map',
        name: 'map',
    },
    'consumer-bookings': {
        url: '/consumers/consumer-bookings',
        name: 'consumer_bookings',
    },
    'consumers': {
        url: '/consumers',
        name: 'consumers',
    },
    'products-events': {
        url: '/products/events',
        name: 'products_events',
    },
    'products-logs': {
        url: '/products/logs',
        name: 'products_logs',
    },
    'product-containers': {
        url: '/products/containers',
        name: 'barcodes',
    },
    'products-min-quantity': {
        url: '/products/min-quantity',
        name: 'critical_quantities',
    },
    'products': {
        url: '/products',
        name: 'products',
    },
    'order-proposals-products': {
        url: '/order-proposals/products',
        name: 'products',
    },
    'order-proposals': {
        url: '/order-proposals',
        name: 'order_proposals',
    },
    'sales_events_stages': {
        url: '/sales-events/stages',
        name: 'sales_events_stages',
    },
    'sales_events_types': {
        url: '/sales-events/types',
        name: 'sales_events_types',
    },
    'sales-events': {
        url: '/sales-events',
        name: 'sales_events',
    },
    'sales-opportunities-types': {
        url: '/sales-opportunities/types',
        name: 'types',
    },
    'sales-opportunities': {
        url: '/sales-opportunities',
        name: 'sales_opportunities',
    },
    'articles-exposure': {
        url: '/articles-exposure',
        name: 'products_exposure',
    },
    'features': {
        url: '/features',
        name: 'features',
    },
    'article-history': {
        url: '/article-history',
        name: 'comments',
    },
    'subcontractors': {
        url: '/subcontractors',
        name: 'subcontractors',
    },
    'cameras': {
        url: '/system-settings/cameras',
        name: 'cameras',
    },
    'cameras-watch': {
        url: '/cameras',
        name: 'cameras',
    },
    'dimensions': {
        url: '/dimensions',
        name: 'dimensions',
    },
    'suppliers-evaluation': {
        url: '/suppliers/evaluation',
        name: 'suppliers_evaluation'
    },
    'suppliers-delayed-arrivals': {
        url: '/suppliers/delayed-arrivals',
        name: 'delayed_arrivals',
    },
    'suppliers-debts': {
        url: '/suppliers/debts',
        name: 'debts',
    },
    'suppliers': {
        url: '/suppliers',
        name: 'suppliers',
    },
    'remnants-movement': {
        url: '/remnants-movement',
        name: 'material_movement',
    },
    'materials-tests': {
        url: '/materials-tests',
        name: 'materials_tests',
    },
    'material-quants': {
        url: '/material-quants',
        name: 'material_quants',
    },
    'inventory-min-quantity': {
        url: '/inventory/min-quantity',
        name: 'critical_quantities',
    },
    'inventory': {
        url: '/inventory',
        name: 'inventory',
    },
    'inventory-categories': {
        url: '/inventory-categories',
        name: 'inventory_categories',
    },
    'inventory-types': {
        url: '/inventory-types',
        name: 'inventory_types',
    },
    'inventorization': {
        url: '/inventorization',
        name: 'inventorization',
    },
    'inventorization-by-date': {
        url: '/inventorization-by-date',
        name: 'inventorization_by_date',
    },
    'roles': {
        url: '/roles',
        name: 'roles',
    },
    'notifications': {
        url: '/notifications',
        name: 'notifications',
    },
    'plans': {
        url: '/workplaces/map',
        name: 'plans',
    },
    'amortization': {
        url: '/assets/amortization',
        name: 'amortization',
    },
    'assets': {
        url: '/assets',
        name: 'assets',
    },
    'services': {
        url: '/services',
        name: 'services',
    },
    'taxes': {
        url: '/taxes',
        name: 'taxes',
    },
    'tax-types': {
        url: '/tax-types',
        name: 'tax_types',
    },
    'ledger-types': {
        url: '/ledger-types',
        name: 'ledger_types',
    },
};

@Component({
    selector: 'pm-toolbar',
    template: `
        @if (_inited) {
            <div fxLayout="column">
                <div class="Toolbar">
                    <div class="Toolbar-wrapper"
                         fxLayout="column">
                        <div fxLayout="row wrap"
                             fxLayoutAlign="end center">
                            <div class="Toolbar-Title"
                                 fxLayout="column">
                                <h5 class="Toolbar-Headline">
                                <span class="Toolbar">
                                    @for (state of states; track state) {
                                        <span class="Toolbar-link">
                                            @if (!hideBacklink) {
                                                <a [routerLink]="state.url">{{ state.name | translate }}</a>
                                                <span> – </span>
                                            }
                                        </span>
                                    }
                                    {{ title | translate }}
                                </span>
                                </h5>
                            </div>
                            <div fxFlex></div>
                            <div class="Toolbar-actions Btn-actions RightPadding"
                                 fxLayout="row wrap">
                                @for (action of actions; track action) {
                                    @if (action.isVisible() && !action.hidden) {
                                        <pro-btn [icon]="action.icon"
                                                 [theme]="action.theme"
                                                 (onClick)="handleActionClick($event, action, item)"
                                                 [disabled]="action.isDisabled()"
                                                 [tooltip]="action.tooltip | translate"
                                                 [tooltipPosition]="'left'"
                                                 [disabledTooltip]="action.disabledTooltip"
                                                 [pending]="action.pending"></pro-btn>
                                    }
                                }
                                @if (actionsEditable) {
                                    <div class="Toolbar-actions-edit">
                                        <pro-btn
                                          [icon]="'edit'"
                                          [theme]="null"
                                          (onClick)="editActions()"
                                          [tooltip]="'customize_actions' | translate"
                                          [tooltipPosition]="'left'"></pro-btn>
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="Toolbar-tools" fxLayout="row" fxLayoutAlign="start center">
                            @if (entity && hasColor) {
                                <pro-color [value]="entity.color" (onChange)="handleColorChange($event)"
                                           [noPadding]="true" [noLabel]="true"></pro-color>
                            }

                            @if (subtitle) {
                                <div fxLayout="row" fxLayoutAlign="start center" class="RightMargin">
                                    <div class="Toolbar-Subheader" fxLayout="row" fxLayoutAlign="start center">
                                        @if (subtitleIcon) {
                                            <span class="{{ subtitleIcon.color }}" [ngClass]="{ 'Subtitle-warn' : subtitleWarn }">
                                            <fa [name]="subtitleIcon.icon"></fa>
                                        </span>
                                        }
                                        <span> {{ subtitle | translate }}</span>
                                    </div>
                                </div>
                            }

                            @if (entity && entityName && hasTags) {
                                <pro-tags [item]="entity"
                                          [config]="{ entity: entityName, tagType: tagType }"></pro-tags>
                            }
                        </div>
                    </div>
                </div>
                @if (parentState) {
                    <pm-tabs [parentState]="parentState"
                             [filter]="parentStateFilter"
                             [additionalState]="additionalState"></pm-tabs>
                }
            </div>
        }
    `,
    styles: [`
        .Subtitle-warn {
            color: #da271a;
            animation: blinker 1s step-start infinite;
        }

        .Toolbar-actions {
            min-width: 10vw;
            overflow-x: auto;
            justify-content: flex-end;
        }
        .Toolbar-actions-edit {
          border-left: 1px solid #444;
          padding-right: 8px;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ToolbarComponent implements OnInit {
    actions: Action[];
    actionsEditable: boolean;
    hideBacklink: boolean;
    title: any;
    subtitle: any;
    subtitleIcon: any;
    entity: any;
    newsFeedEntityType: any;
    item: any;
    entityName: any;
    tagType: TagType;
    parentState: string;
    parentStateFilter: any;
    additionalState: any;
    states: any[];
    defaultTitle: any;
    hasColor: boolean;
    hasTags: boolean;
    _inited: boolean = true;
    subtitleWarn: boolean;
    storageKey: string;
    savedActions: Action[];

    constructor(
        private promanState: PromanStateService,
        private Title: TitleService,
        private cd: ChangeDetectorRef,
        private Toolbar: ToolbarService,
        private Location: Location,
        private Router: Router,
        private Entity: Entity,
        private Dialog: Dialog,
        private LocalStorage: LocalStorageService,
        private CursorLoading: CursorLoadingService,
    ) {

        this.Router.events.pipe(
            filter((event) => (event instanceof NavigationEnd )))
            .subscribe((event: any) => {
                setTimeout(() => {
                    if (!document.querySelector('pm-toolbar-driver')) this.Toolbar.reset();

                    this.setStates(event.urlAfterRedirects);

                    this.CursorLoading.stop();
                }, 25);
            });

      this.Toolbar.timeStampSubject.subscribe(() => {
        this.refresh();

        if (this.entityName) {
          this.storageKey = `${this.entityName}`;
        }

        this.savedActions = (this.LocalStorage.getItem(TOOLBAR_ACTIONS_STORAGE_KEY) || {})[this.storageKey];
      });
    }

    ngOnInit() {
        this.setStates(this.Location.path());

        if (this.Toolbar.title) this.title = this.Toolbar.title.toString();
    }

    setStates = (url: any) => {
        const states: any = [];
        this.hideBacklink = this.Toolbar.hideBacklink;

        for (const key in toolbarStates) {
            const state = toolbarStates[key];

            if (!this.Toolbar.title && url === state.url) {
                this.defaultTitle = state.name;
            }

            if (url !== state.url && url.startsWith(`${state.url}/`)) {
                states.push(toolbarStates[key]);
                break;
            }
        }

        this.states = states;

        if (this.Toolbar.backlink) {
            this.states = [
                {
                    url: '/' + this.promanState.getRoute(this.Toolbar.backlink.state)[0],
                    name: this.Toolbar.backlink.name,
                }
            ];
        }
        this.refresh();
        this.cd.markForCheck();
    };

    handleActionClick = ($event: any, action: any, item: any) => {
        const promise = action.callback($event, item);

        const disablePending = () => {
            action.pending = false;
            this.cd.markForCheck();
        };

        if (promise && promise.then) {
            action.pending = true;

            promise
                .then(disablePending)
                .catch(disablePending);
        }

    };

    refresh() {
        const Toolbar: ToolbarService = this.Toolbar;

        this.actions = Toolbar.actions;

        if (this.savedActions) {
          if (this.actions?.length && this.savedActions.length) {
            this.actions = this.savedActions;
          }
        }

        this.actionsEditable = Toolbar.actionsEditable;

        this.item = Toolbar.item;
        this.title = (Toolbar.title || this.defaultTitle) ? (Toolbar.title || this.defaultTitle).toString() : '';
        this.subtitle = Toolbar.subtitle;
        this.subtitleIcon = Toolbar.subtitleIcon;
        this.entity = Toolbar.entity;
        this.newsFeedEntityType = Toolbar.newsFeedEntityType;
        this.entityName = Toolbar.entityName;
        this.tagType = Toolbar.tagType;
        this.parentState = Toolbar.parentState;
        this.parentStateFilter = Toolbar.parentStateFilter;
        this.hasColor = Toolbar.hasColor;
        this.hasTags = Toolbar.hasTags;
        this.subtitleWarn = Toolbar.subtitleWarn;
        this.additionalState = Toolbar.additionalState;

        this.storageKey = null;

        this.adjustActions();

        if (this.Toolbar.backlink && this.states.length && this.states[0].name !== this.Toolbar.backlink.name) {
            this.setStates('/' + this.promanState.getRoute(this.Toolbar.backlink.state)[0]);
        }

        this._inited = false;

        setTimeout(() => {
            this._inited = true;
            this.cd.markForCheck();
        })
    }

    handleColorChange = (color: any) => {
        return this.Entity.get({name: this.entityName}).update({id: this.entity.id, color});
    };

    adjustActions = () => {
        if (this.actions) {
            for (const action of this.actions) {
                if (typeof action.isVisible === 'undefined') action.isVisible = () => true;
                if (typeof action.isDisabled === 'undefined') action.isDisabled = () => false;
            }
        }
    };

    editActions = () => {
        this.Dialog.open(ToolbarActionsDialogComponent, { actions: this.actions })
          .then((value: Action[] | undefined) => {
              if (value && value !== (1 as any) && value !== ('1' as any)) {
                  this.actions = value;
                  this.adjustActions();
                  this.cd.markForCheck();

                  this.saveActions();
              }
          });
    };

    saveActions() {
      if (this.storageKey) this.LocalStorage.setItem(TOOLBAR_ACTIONS_STORAGE_KEY, { ...(null || {}), [this.storageKey]: this.actions });
    }

}
