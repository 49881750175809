import { Component, NgZone, OnInit } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { Dialog } from '../services/dialog.service';
import { PreferencesService } from '@proman/services/preferences.service';
import { PromanStateService } from '../services/proman-state.service';
import { ObservablesService } from '@proman/services/observables.service';
import { BugTrackerDialogComponent } from './bug-tracker-dialog.component';
import html2canvas from 'html2canvas';
import { ACL } from '@proman/services/acl.service';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { Store } from '@ngrx/store';
import { getCurrUser } from '@proman/store/curr-user';
import { distinctUntilChanged } from '@proman/rxjs-common';
import { AuthService } from '@proman/services/auth.service';

@Component({
    selector: 'pm-account',
    template: `
        <div fxLayout="column" fxLayoutAlign="start center">
            <div class="Account"
                 fxLayout="column"
                 fxLayoutAlign="start center">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="Account-photo"
                         fxLayout="row"
                         fxLayoutAlign="center center">
                        <mat-menu #userMenu="matMenu">
                            <button *ngIf="canViewProfile"
                                    mat-menu-item
                                    (click)="navigate('account')">
                                <fa name="users-cog"></fa>
                                {{ 'user_account' | translate }}
                            </button>

<!--                            <button mat-menu-item-->
<!--                                    (click)="navigate('user')">-->
<!--                                <fa name="random"></fa>-->
<!--                                {{ 'switch_user' | translate }}-->
<!--                            </button>-->

                            <button mat-menu-item
                                    (click)="trackBug($event)">
                                <fa name="exclamation-triangle"></fa>
                                {{ 'support' | translate }}
                            </button>

                            <button mat-menu-item
                                    (click)="navigate('logout')">
                                <fa name="sign-out-alt"></fa>
                                {{ 'logout' | translate }}
                            </button>
                        </mat-menu>
                        <pm-user-thumb [thumb]="currUser.photo"
                                       [isUserThumb]="true"
                                       [matMenuTriggerFor]="userMenu"
                                       (click)="handleMenuOpen()"></pm-user-thumb>

                    </div>
                </div>
                <div fxLayout="row"
                     fxLayoutAlign="center center"
                     class="Account-name">
                    <div class="Account-name--label">
                        <a [routerLink]="'UserOptions' | pmSref">
                            {{ currUser.person?.firstName }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        :host { overflow: hidden; }
        pm-user-thumb { width: 100%; height: 100%; }
        .newVersionAvailable {
            color: #0fd416;
            position: relative;
            top: 3px;
            left: 4px;
            font-size: 0.85em;
        }
        fa {
            padding-right: 4px;
            font-size: 0.9em;
        }
    `]
})

export class AccountComponent implements OnInit {
    currUser: CurrUser = {} as CurrUser;
    systemOptionsEntity: any;
    newVersion: boolean;
    availableCompanies: any[];
    canViewProfile: boolean;

    constructor(
        private NgZone: NgZone,
        private store: Store,
        private PromanState: PromanStateService,
        private Entity: Entity,
        private Dialog: Dialog,
        private Preferences: PreferencesService,
        private Observables: ObservablesService,
        private Auth: AuthService,
        private ACL: ACL,
    ) {
        this.systemOptionsEntity = Entity.get('system_options');
        this.store.select(getCurrUser)
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                this.currUser = value;
            });
    }

    ngOnInit() {
        this.Observables.userPhotoTimestamp.subscribe(() => this.loadPhoto());

        this.loadPhoto();

        window['handleCanvas'] = (event: any, canvas: any) => {
            this.NgZone.run(() => {
                this.handleCanvas(event, canvas);
            });
        };

        this.checkProfileView();
    }

    checkProfileView() {

        this.canViewProfile =
            (this.currUser.type === 'employee') ?
                this.ACL.check('employee.view') :
                (this.currUser.isAgent) ?
                    this.ACL.check('agent.view') :
                    (this.currUser.isCustomer) ?
                        this.ACL.check('customer_employee.view') :
                        false;

    }

    // switchUser() {
    //     let validTokens = this.Preferences.tokens();
    //     let users = Object.keys(validTokens);
    //
    //     if (users.length > 1) {
    //         this.Dialog
    //             .open2(UsersSwitcherDialogComponent, { users, validTokens });
    //
    //     } else {
    //         this.PromanState.to('login');
    //
    //     }
    // }

    navigate(type: any) {

        if (type === 'account') {
            this.PromanState.to('UserOptions');

        // } else if (type === 'user') {
        //     this.switchUser();

        } else if (type === 'logout') {
            this.Auth.logout();

        }
    }

    loadPhoto() {
        this.Entity
            .get(this.currUser.isCustomer && 'customer_employee' || this.currUser.type)
            .get({ id: this.currUser.person.id , select: ['id'], join: ['photo']})
            .then((response: any) => {
                if (response) {
                    this.currUser = { ...this.currUser, photo: response.photo };
                }
            });
    }

    handleMenuOpen = () => {
        if (this.currUser.person.isAdmin) {
            this.systemOptionsEntity
                .version()
                .then((response: any) => {
                    let version = response.version;
                    let posVersion = response.pos;

                    if (version[0] !== version[1] || posVersion[0] !== posVersion[1]) this.newVersion = true;
                });

        }

    };

    cancelCompanyConnection() {
        this.PromanState.go(true);
    }

    handleCanvas(event: any, canvas: any) {
        this.Dialog.open2(BugTrackerDialogComponent, {
            screenshot: canvas.toDataURL('image/png'),
            url: window.location.href
        });
    }

    trackBug($event: any) {
        setTimeout(() => {
            try {
                (html2canvas as any)(document.body).then((canvas: any) => window['handleCanvas']($event, canvas));
            } catch (e) {
                console.warn('html 2 canvas issue: ', e);
            }
        }, 250);
    }
}
